import React, {useEffect, useRef, useState} from 'react';
import {Input, message, Button} from 'antd';

import './PasswordForm.scss';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {openModal} from '../../store/reducers/modalSlice';
import clickSound from '../../assets/sounds/mario.wav';
import moneySound from '../../assets/sounds/moneySound.wav';
import coin from '../../assets/gifs/coin.gif';
import money from '../../assets/gifs/money.gif';

message.config({
  maxCount: 2,
});

const PasswordForm = ({onSendPassword}) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet,
  );

  const [animateCoin, setAnimateCoin] = useState(false);
  const [animateMoney, setAnimateMoney] = useState(false);

  const [password, setPassword] = useState('');

  const coinAudio = new Audio(clickSound);
  const moneyAudio = new Audio(moneySound);

  let predel = Number(localStorage.getItem('predel')) || 0;
  let payed = String(localStorage.getItem('payed')) || 'false';

  const handleSendPassword = () => {
    const token = localStorage.getItem('token');
    const isMuted = localStorage.getItem('isMuted');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (!password) {
      message.error('Поле пароля не может быть пустым');
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({password}),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || 'Ошибка сервера');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          setAnimateCoin(true);
          isMuted !== 'true' && coinAudio.play();
          setTimeout(() => setAnimateCoin(false), 800);
          message.success('Попытка отправлена');
          setPassword('');
          predel = predel - 1;
          localStorage.setItem('predel', JSON.stringify(predel));
          onSendPassword();
        } else {
          throw new Error(data.message || 'Ошибка при отправке пароля');
        }
      })
      .catch((error) => {
        message.error(error.message || 'Что-то пошло не так');
      });
  };

  const handleSendPasswordRandom = (amount: number) => {
    if (payed === 'false' && amount !== 1) {
      message.error('Купи попытки для генерации паролей');
      dispatch(openModal('buyAttempts'));
      return;
    }
    const token = localStorage.getItem('token');
    const isMuted = localStorage.getItem('isMuted');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password/random`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: amount ? JSON.stringify({multiply: amount}) : '',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || 'Ошибка сервера');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          if (amount === 1) {
            setAnimateCoin(true);
            isMuted !== 'true' && coinAudio.play();
            setTimeout(() => setAnimateCoin(false), 800);
          } else {
            setAnimateMoney(true);
            isMuted !== 'true' && moneyAudio.play();
            setTimeout(() => setAnimateMoney(false), 1600);
          }

          message.success('Попытка отправлена');
          setPassword('');
          const updatePredel = predel - amount;

          localStorage.setItem(
            'predel',
            updatePredel === 0 ? '0' : JSON.stringify(updatePredel),
          );
          onSendPassword();
        } else {
          throw new Error(data.message || 'Ошибка при отправке пароля');
        }
      })
      .catch((error) => {
        message.error(error.message || 'Что-то пошло не так');
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendPassword();
    }
  };

  const handleCharKeyPress = (e) => {
    const isCyrillic = /[а-яА-ЯёЁ]/.test(e.currentTarget.value);
    if (isCyrillic) {
      message.error('Можно вводить только латинские буквы, символы и цифры');

      e.preventDefault();
    } else setPassword(e.target.value);
  };

  return (
    <div className="PasswordForm_root">
      {animateMoney && <img src={money} alt="money" className="video" />}
      <div className="input_frame">
        <div className="address">
          <p className="attempt-counter">У вас есть {predel} попыток</p>
          <div className="input-container">
            {animateCoin && <img src={coin} alt="coin" className="coin-gif" />}
            <Input
              className="password-input"
              placeholder="введи СВОЮ попытку"
              value={password}
              onChange={(e) => handleCharKeyPress(e)}
              onKeyPress={handleKeyPress}
            />
            <button className="try-pass" onClick={handleSendPassword}></button>
          </div>
          BTC wallet:{' '}
          {state === '18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB' ? '30' : '0.08'} btc
        </div>
      </div>
      <div className="form-button">
        <div className={`try-count`}>
          ГЕНЕРАТОР ПОПЫТОК
          <Button
            className="try-rand"
            onClick={() => handleSendPasswordRandom(1)}>
            СГЕНЕРИРОВАТЬ
          </Button>
          МНОЖИТЕЛЬ ПОПЫТОК
          <div className="multiplier_container">
            <div className="multiplier_buttons">
              <Button
                className="multiplier_button"
                onClick={() => handleSendPasswordRandom(100)}>
                x100
              </Button>
              <Button
                className="multiplier_button second"
                onClick={() => handleSendPasswordRandom(1000)}>
                x1000
              </Button>
              <Button
                className="multiplier_button third"
                onClick={() => handleSendPasswordRandom(10000)}>
                x10K
              </Button>
            </div>{' '}
            <Button
              className="try-add"
              onClick={() => dispatch(openModal('buyAttempts'))}>
              ЕЩЕ ПОПЫТОК
            </Button>
            0.25 TON = 1500 FNP
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordForm;
